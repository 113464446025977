<!-- <template>
  <div style="width: 100%; height: 100%">
    <Head :is-url="'true'" @click="goPrev"></Head>
    <div style="height: 100%">
      <iframe :src="url" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div class="iframe-container">
      <iframe
        :src="url"
        class="iframe"
        frameborder="0"
        scrolling="no"
        @load="onIframeLoad"
      ></iframe>
    </div>
    <Foot></Foot>
  </div>
</template> -->
<template>
  <div style="width: 100%; height: 100%">
    <Head :is-url="'true'" @click="goPrev"></Head>
    <div style="height: 100%">
      <iframe :src="url" width="30%" height="100%" frameborder="0"></iframe>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "iframe",
  components: { Head, Foot },
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    this.url = this.$route.query.url;
  },
  methods: {
    goPrev() {
      if (
        this.url.toString().indexOf("nav.tourgansu.com") > 0 ||
        this.url.toString().indexOf("exchange.travel") > 0
      ) {
        this.$router.push("/");
      } else {
        this.$router.push({ path: "/contentplat" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
iframe-container {
  position: relative;
  width: 80%;
  height: 100vh; /* 适应屏幕高度 */
}

.iframe {
  width: 80%;
  height: 100%;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
</style>
