import { render, staticRenderFns } from "./iframeH5.vue?vue&type=template&id=7c1af3b1&scoped=true&"
import script from "./iframeH5.vue?vue&type=script&lang=js&"
export * from "./iframeH5.vue?vue&type=script&lang=js&"
import style0 from "./iframeH5.vue?vue&type=style&index=0&id=7c1af3b1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c1af3b1",
  null
  
)

export default component.exports